import { faBabyCarriage, faShoppingBasket } from '@fortawesome/pro-solid-svg-icons';
import {
    ESHOP_BANNER_TEXT,
    HINT,
    PREGNANCY_NEWSLETTER_BANNER_TEXT, PREGNANCY_NEWSLETTER_HINT,
    PRODUCT_ESHOP_BADGE,
} from 'mk/autogenerated/translations/HomeBaseBannerComponent.d4851c861702d8a91512fc79d3310573'
import { ACTIVE_SERVER_ID, BASE_URL } from 'mk/settings';
import HomeBaseBanner from 'mk2/apps/home/components/HomeBaseBanner';
import { eshopWithChannelUrl } from 'mk2/apps/home/urls';
import React from 'react';


interface OwnProps {
    className?: string;
}

type Props = OwnProps;

const HomeBaseBannerComponent = React.memo<Props>(({className,
}) => {

    const eshopHomeLink = {
        201: `${eshopWithChannelUrl}/sell-stroller/?utm_source=modrykonik&utm_medium=titulka&utm_campaign=vykup_kocikov&utm_content=titulka_pevna_pozicia`,
        202: `${eshopWithChannelUrl}/?utm_source=modrykonik&utm_medium=titulka&utm_content=pevna_pozicia&utm_campaign=eshop`,
    }[ACTIVE_SERVER_ID];

    const pregnancyNLLink = {
        201: `${BASE_URL}/som-tehotna/newsletter-pre-tehotne/?utm_source=modrykonik&utm_medium=titulka&utm_content=pevna_pozicia&utm_campaign=newsletter`,
        202: `${BASE_URL}/jsem-tehotna/newsletter-pro-tehotne/?utm_source=modrykonik&utm_medium=titulka&utm_content=pevna_pozicia&utm_campaign=newsletter`,
    }[ACTIVE_SERVER_ID];

    const hintText = {
        201: HINT,
        202: PRODUCT_ESHOP_BADGE,
    }[ACTIVE_SERVER_ID];

    const bannerIcon = {
        201: faBabyCarriage,
        202: faShoppingBasket,
    }[ACTIVE_SERVER_ID];

    return (
    <>
        <HomeBaseBanner
            className={className}
            linkTo={pregnancyNLLink}
            text={PREGNANCY_NEWSLETTER_BANNER_TEXT}
            hintText={PREGNANCY_NEWSLETTER_HINT}
        />
        <HomeBaseBanner
            className={className}
            linkTo={eshopHomeLink}
            icon={bannerIcon}
            hintText={hintText}
            text={ESHOP_BANNER_TEXT}
        />
    </>
    );
});

HomeBaseBannerComponent.displayName = 'HomeBaseBannerComponent';

export default HomeBaseBannerComponent;
